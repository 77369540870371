<script setup lang="ts">
defineOptions({
  name: 'ActorsIndex',
})
definePageMeta({
  name: 'actors-index',
  key: route => `${route.params.server ?? useRuntimeConfig().public.domain}:${route.params.account}`,
  layout: 'default',
})

const url = '/api/timelines/actors'
const channel = 'timeline:actors'
</script>

<template>
  <div class="explore-page">
    <HeaderMain>
      <template #header-start>
        <LogoIndi />
      </template>
    </HeaderMain>
    <TimelineActors :url="url" :channel="channel" />
  </div>
</template>

<style lang="scss" scoped>
  .logo {
    width: calc(var(--base-size) * 4);
  }
</style>
